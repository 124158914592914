import { Box, Skeleton } from '@mui/material';

export const SkeletonPage: React.FC = () => {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Skeleton variant="rectangular" width={200} height={44} />
        <Skeleton variant="rectangular" width={160} height={44} />
      </Box>

      <Skeleton
        variant="rectangular"
        width="100%"
        height="77vh"
        sx={{ borderRadius: '4px' }}
      />
    </>
  );
};
