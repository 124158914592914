import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormHelperText } from '@mui/material';
import { FarmlandsTypeDto } from 'entities/catalog';
import { Form } from 'formik';
import { Input } from 'shared/ui';

type Props = {
  isLoading: boolean;
  isPolygonError: boolean;
  handleClickBack: () => void;
  farmlandsTypesAll?: FarmlandsTypeDto[];
};

export const FarmlandMapForm: React.FC<Props> = ({
  isLoading,
  isPolygonError,
  farmlandsTypesAll,
  handleClickBack,
}) => {
  const { i18n, t } = useTranslation();

  return (
    <Box
      component={Form}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Input
        label="name"
        name="farmland_name"
        placeholder={`${t('placeholderList.insertName')}`}
        InputLabelProps={{ shrink: true }}
        disabled={isLoading}
      />
      <div>
        <Input
          select
          label="farmlandType"
          name="farmland_type"
          placeholder={`${t('placeholderList.insertPassword')}`}
          defaultValue={-1}
          defaultMenuItems={{
            value: '-1',
            text: 'wayStatusNone',
          }}
          menuItems={farmlandsTypesAll?.reduce((obj, item) => {
            obj[item.type_id] =
              item?.[
                `farmland_type_name_${i18n.language}` as keyof Omit<
                  FarmlandsTypeDto,
                  'type_id'
                >
              ];
            return obj;
          }, {} as { [id: number]: string })}
          disabled={isLoading}
        />
        <FormHelperText error>
          {isPolygonError && t('validationList.notFoundPolygon')}
        </FormHelperText>
      </div>

      <Box sx={{ display: 'flex', gap: 4 }}>
        <Button
          fullWidth
          variant="contained"
          color="neutral"
          onClick={handleClickBack}
          disabled={isLoading}
        >
          {t('cancel')}
        </Button>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};
