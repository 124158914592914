import { number, object } from 'yup';

export const assignmentValidationSchema = object().shape({
  employee_id: number().required('fillField'),
  machinery_id: number().required('fillField'),
  unit_id: number().required('fillField'),
  planned_fuel_consumption: number()
    .min(0, 'lessThanZero')
    .required('fillField'),
});
