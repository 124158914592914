import { lazy } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';
import { RequireUser } from 'features/authentication/requireUser';
import { Login } from 'pages/login';
import { Layout } from 'widgets/layout';

const TasksPage = lazy(() => import('./tasks'));
const TaskAddPage = lazy(() => import('./taskAdd'));
const TaskEditPage = lazy(() => import('./taskEdit'));
const TaskView = lazy(() => import('./taskView'));
const TaskMore = lazy(() => import('./taskMore'));
const EmployeesPage = lazy(() => import('./employees'));
const MachinerMPage = lazy(() => import('./machinery'));
const UnitsPage = lazy(() => import('./units'));
const FarmlandsPage = lazy(() => import('./farmlands'));
const FarmlandsAddPage = lazy(() => import('./farmlandsAdd'));
const FarmlandsEditPage = lazy(() => import('./farmlandsEdit'));
const NoMatchPage = lazy(() => import('./noMatch'));

export const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to="/tasks" replace />} />

      <Route path="/" element={<Layout />}>
        <Route element={<RequireUser />}>
          <Route index path="tasks" element={<TasksPage />} />
          <Route path="tasks/add" element={<TaskAddPage />} />
          <Route path="tasks/edit/:id" element={<TaskEditPage />} />
          <Route path="tasks/view/:id" element={<TaskView />} />
          <Route path="tasks/more/:id" element={<TaskMore />} />
          <Route path="employees" element={<EmployeesPage />} />
          <Route path="machinery" element={<MachinerMPage />} />
          <Route path="units" element={<UnitsPage />} />
          <Route path="farmlands" element={<FarmlandsPage />} />
          <Route path="farmlands/add" element={<FarmlandsAddPage />} />
          <Route path="farmlands/edit/:id" element={<FarmlandsEditPage />} />
          <Route path="*" element={<NoMatchPage />} />
        </Route>
      </Route>

      <Route path="/login" element={<Login />} />
    </>,
  ),
);
