/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';

const getMachineryOption = (optionTitle: string | number) => {
  if (typeof optionTitle === 'string' && optionTitle.includes('/')) {
    const titleArray = optionTitle.split('/');
    const machineryModel = titleArray[0];
    const machineryNumber = titleArray[1];
    return (
      <Stack direction="row">
        <Typography sx={{ pr: 1 }}>{machineryModel}</Typography>/
        <Typography sx={{ opacity: 0.5, ml: 1 }}>{machineryNumber}</Typography>
      </Stack>
    );
  } else {
    return false;
  }
};

type InputProps = {
  name: string;
  label: string;
  hidehelperText?: boolean;
  helperText?: string;
  menuItems?:
    | { [key: string]: string }
    | { [key: string]: number }
    | Array<string>
    | Array<number>;
  defaultMenuItems?: { value: string; text: string };
};

export const Input: React.FC<TextFieldProps & InputProps> = (props) => {
  const {
    helperText,
    hidehelperText = false,
    menuItems,
    defaultMenuItems,
    name,
    label,
    ...rest
  } = props;
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<any>();
  const isError = !!(!!errors[name] && touched[name]);

  const getError = (key: string) =>
    !!errors[key] && touched[key] ? t(`validationList.${[errors[key]]}`) : '';

  return (
    <Box sx={{ width: '100%' }}>
      <TextField
        fullWidth
        name={name}
        label={t(label)}
        value={props.select && !values[name] ? -1 : values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        error={isError}
        {...rest}
        SelectProps={{
          style:
            values[name] < 1 || !values[name]
              ? { color: palette.text.disabled }
              : {},
        }}
      >
        {defaultMenuItems && (
          <MenuItem value={defaultMenuItems.value} sx={{ display: 'none' }}>
            {t(defaultMenuItems.text)}
          </MenuItem>
        )}
        {menuItems &&
          (Array.isArray(menuItems)
            ? menuItems.map((el, index) => (
                <MenuItem key={index} value={el}>
                  {typeof el === 'string' ? t(el) : el}
                </MenuItem>
              ))
            : Object.keys(menuItems).map((key, index) => (
                <MenuItem key={index} value={key}>
                  {getMachineryOption(menuItems[key])
                    ? getMachineryOption(menuItems[key])
                    : typeof menuItems[key] === 'string'
                    ? t(menuItems[key] as string)
                    : menuItems[key]}
                </MenuItem>
              )))}
      </TextField>
      {!hidehelperText && (
        <FormHelperText error>{getError(name)}</FormHelperText>
      )}
    </Box>
  );
};
