import { baseApi, TagTypes } from 'shared/api';

import {
  CoordinatesDataByTaskGetResponse,
  MachineryPositionByTaskGetResponse,
  TaskAddRequest,
  TaskAddResponse,
  TaskChangeRequest,
  TaskChangeResponse,
  TaskGetResponse,
  TasksGetResponse,
  TaskStatisticsGetResponse,
  TaskStatusGetResponse,
} from './types';

export const tasksApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllTasks: build.query<TasksGetResponse['tasks'], void>({
      query: () => ({
        url: 'api/get_all_tasks',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ task_id }) => ({
                type: TagTypes.TASK_TAG,
                id: task_id,
              })),
              TagTypes.TASK_TAG,
            ]
          : [TagTypes.TASK_TAG],
      transformResponse: (response: TasksGetResponse) => {
        return response.tasks;
      },
    }),
    getTask: build.query<TaskGetResponse['task'], number>({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('task_id', id.toString());

        return {
          url: `api/get_task?${searchParams}`,
          method: 'GET',
        };
      },
      providesTags: (result, error, arg) => [
        { type: TagTypes.TASK_TAG, id: arg },
      ],
      transformResponse: (response: TaskGetResponse) => {
        return response.task;
      },
    }),
    addTask: build.mutation<number, TaskAddRequest>({
      query: ({ begin_datetime, end_datetime, farmland_id, type }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('begin_datetime', begin_datetime);
        searchParams.append('end_datetime', end_datetime);
        searchParams.append('farmland_id', farmland_id.toString());
        searchParams.append('type', type.toString());

        return {
          url: `api/add_task?${searchParams}`,
          method: 'POST',
        };
      },
      invalidatesTags: [TagTypes.TASK_TAG],
      transformResponse: (response: TaskAddResponse) => {
        return response.task_id;
      },
    }),
    changeTask: build.mutation<TaskChangeResponse, TaskChangeRequest>({
      query: ({
        task_id,
        farmland_id,
        begin_datetime,
        end_datetime,
        task_type,
      }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('farmland_id', farmland_id.toString());
        searchParams.append('begin_datetime', begin_datetime);
        searchParams.append('end_datetime', end_datetime);
        searchParams.append('task_type', task_type.toString());
        searchParams.append('task_id', task_id.toString());

        return {
          url: `api/change_task?${searchParams}`,
          method: 'PUT',
        };
      },
      invalidatesTags: [TagTypes.TASK_TAG],
    }),
    deleteTask: build.mutation<void, number>({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('task_id', id.toString());

        return { url: `api/delete_task?${searchParams}`, method: 'DELETE' };
      },
      invalidatesTags: [TagTypes.TASK_TAG],
    }),

    getTaskStatus: build.query<TaskStatusGetResponse, number>({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('task_id', id.toString());

        return {
          url: `api/get_task_status?${searchParams}`,
          method: 'GET',
        };
      },
    }),
    getTaskStatistics: build.query<TaskStatisticsGetResponse, number>({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('task_id', id.toString());

        return {
          url: `api/get_task_statistics?${searchParams}`,
          method: 'GET',
        };
      },
      providesTags: [TagTypes.TASK_ASSIGNMENT_TAG],
    }),
    getCoordinatesDataByTask: build.query<
      CoordinatesDataByTaskGetResponse['coordinates_data'],
      number
    >({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('task_id', id.toString());

        return {
          url: `api/get_coordinates_data_by_task?${searchParams}`,
          method: 'GET',
        };
      },
      transformResponse: (response: CoordinatesDataByTaskGetResponse) => {
        return response.coordinates_data;
      },
    }),
    getMachineryPositionByTask: build.query<
      MachineryPositionByTaskGetResponse,
      number
    >({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('task_id', id.toString());

        return {
          url: `api/get_machinery_position_by_task?${searchParams}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetAllTasksQuery,
  useGetTaskQuery,
  useAddTaskMutation,
  useChangeTaskMutation,
  useDeleteTaskMutation,
  useGetTaskStatusQuery,
  useGetTaskStatisticsQuery,
  useGetMachineryPositionByTaskQuery,
  useGetCoordinatesDataByTaskQuery,
} = tasksApi;
