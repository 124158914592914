import { createTheme } from '@mui/material/styles';
import * as varColor from 'app/styles/colors';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    active: Palette['primary'];
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    active: PaletteOptions['primary'];
  }

  interface TypeText {
    light: string;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    active: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: varColor.lightGreen,
      contrastText: varColor.white,
    },
    error: {
      main: varColor.red,
    },
    text: {
      light: varColor.lightBlack,
    },
    neutral: {
      main: varColor.lightGrey,
      contrastText: varColor.blackGrey,
      dark: varColor.grey,
    },
    active: {
      main: 'rgba(33, 150, 243, 0.2)',
      contrastText: varColor.white,
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    body3: {
      fontSize: 10,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: () => ({
        body: {
          overflow: 'hidden',
          backgroundColor: varColor.white,
          fontSize: 12,
          fontWeight: 400,
          lineHeight: '16px',
          color: varColor.text,
        },
        p: {
          margin: 0,
        },
      }),
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 10,
          lineHeight: '13px',
          minHeight: 13,
          fontWeight: 400,
          margin: '0 0 7px',
          marginTop: 3,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          border: '1px solid white',
          '&.active': {
            border: '1px solid black',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 110,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: varColor.lightGreen,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '16px',
        },
      },
    },
  },
});
