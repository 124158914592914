import { useLocation } from 'react-router-dom';

// Example
// https://some-site.com/name/edit/1
//
// pathnameArr = ['', 'name', 'edit', '1']
// You can get also second parameter - 'edit'

type Props = {
  add: string;
  edit: string;
};

export const usePageTitle = ({ add, edit }: Props) => {
  const { pathname } = useLocation();
  const pathnameArr = pathname.split('/');

  return pathnameArr[2]
    ? {
        add,
        edit,
      }[pathnameArr[2] as keyof Props]
    : '';
};
