import React from 'react';
import { Box } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { Modal } from 'features/modal';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ObjectSchema } from 'yup';

type Props = {
  isOpen: boolean;
  title: string;
  initialValues: AnyObject;
  validationSchema: ObjectSchema<AnyObject>;
  onClose: () => void;
  onSubmit: (
    values: AnyObject,
    formikHelpers: FormikHelpers<AnyObject>,
  ) => void;
  children: React.ReactNode;
  primaryText?: string;
  secondaryText?: string;
  maxWidth?: DialogProps['maxWidth'];
  formId?: string;
};

export const MainPageDialog: React.FC<Props> = ({
  isOpen,
  title,
  initialValues,
  validationSchema,
  onClose,
  onSubmit,
  children,
  primaryText = 'save',
  secondaryText = 'cancel',
  maxWidth,
  formId,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      enableReinitialize={true}
    >
      {({ resetForm }: FormikProps<typeof initialValues>) => {
        const handleClose = () => {
          onClose();
          resetForm();
        };

        return (
          <Modal
            open={isOpen}
            title={title}
            onClose={handleClose}
            primaryText={primaryText}
            secondaryText={secondaryText}
            PaperProps={{ sx: { width: '100%' } }}
            maxWidth={maxWidth}
            formId={formId}
          >
            <Box
              id={formId}
              component={Form}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 2,
                gap: 0.5,
              }}
            >
              {children}
            </Box>
          </Modal>
        );
      }}
    </Formik>
  );
};
