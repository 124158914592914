import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import {
  hideNotificationSnackBar,
  selectSystemMessagesSlice,
} from 'entities/systemMessages';
import { closeSnackbar, enqueueSnackbar, OptionsObject } from 'notistack';
import { useAppDispatch, useAppSelector } from 'shared/model';

export const SystemMessageSnackBar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    notificationSnackBar: { message, type, isShow },
  } = useAppSelector(selectSystemMessagesSlice);

  const handleOnClose = () => {
    dispatch(hideNotificationSnackBar());
  };

  const showSnackBar = (message: string, options: OptionsObject) => {
    enqueueSnackbar(message, {
      ...options,
      action: (key) => (
        <IconButton onClick={() => closeSnackbar(key)}>
          <CloseIcon fontSize="small" htmlColor="white" />
        </IconButton>
      ),
    });
  };

  useEffect(() => {
    if (isShow) {
      showSnackBar(t(message), { variant: type });
      handleOnClose();
    }
  }, [isShow]);

  return null;
};
