export enum EmployeeRoles {
  Administrator = 1,
  Operator = 2,
}

export interface EmployeeDto {
  id: number;
  employee_login: string;
  employee_name: string;
  role: EmployeeRoles;
  status: number;
}

export interface EmployeesGetResponse {
  employees: Array<EmployeeDto>;
  status: boolean;
}

export interface EmployeeGetResponse {
  employee: EmployeeDto;
  status: boolean;
}

export interface EmployeeIdResponse {
  employee_id: number;
  status: boolean;
}

export type EmployeeChangeRequest = {
  employee_id: number;
  employee_name: string;
};

export type EmployeePasswordChangeRequest = {
  employee_id: number;
  password: string;
};

export type EmployeeAddRequest = {
  employee_login: string;
  employee_name: string;
  password: string;
  role: number;
};

export type EmployeeChangeStatusRequest = {
  employee_id: number;
  employee_status: number;
};

export type EmployeeChangeStatusResponse = {
  employee_id: number;
  new_status: 1;
  status: boolean;
};
