import { useTranslation } from 'react-i18next';
import { Input } from 'shared/ui';

export const EmployeeChangePasswordForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        label="newPassword"
        name="password"
        placeholder={`${t('placeholderList.insertPassword')}`}
        InputLabelProps={{ shrink: true }}
      />
      <Input
        label="confirmPassword"
        name="confirmPassword"
        placeholder={`${t('placeholderList.insertPassword')}`}
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
};
