import { baseApi, TagTypes } from 'shared/api';

import type {
  MachineryAddRequest,
  MachineryAllGetResponse,
  MachineryChangeRequest,
  MachineryGetResponse,
  MachineryIdResponse,
} from './types';

export const machineryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMachinery: build.query<MachineryGetResponse['machinery'], number>({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('machinery_id', id.toString());

        return {
          url: `api/get_machinery?${searchParams}`,
          method: 'GET',
        };
      },
      providesTags: (result, error, arg) => [
        { type: TagTypes.MACHINERY_TAG, id: arg },
      ],
      transformResponse: (response: MachineryGetResponse) => {
        return response.machinery;
      },
    }),
    getAllMachinery: build.query<MachineryAllGetResponse['machinery'], void>({
      query: () => ({
        url: 'api/get_all_machinery',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ machinery_id }) => ({
                type: TagTypes.MACHINERY_TAG,
                id: machinery_id,
              })),
              TagTypes.MACHINERY_TAG,
            ]
          : [TagTypes.MACHINERY_TAG],
      transformResponse: (response: MachineryAllGetResponse) => {
        return response.machinery;
      },
    }),
    addMachinery: build.mutation<number, MachineryAddRequest>({
      query: ({ model, number, machinery_type, road_fuel_consumption }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('model', model);
        searchParams.append('number', number);
        searchParams.append('machinery_type', machinery_type.toString());
        searchParams.append(
          'road_fuel_consumption',
          road_fuel_consumption.toString(),
        );

        return {
          url: `api/add_machinery?${searchParams}`,
          method: 'POST',
        };
      },
      invalidatesTags: [TagTypes.MACHINERY_TAG],
      transformResponse: (response: MachineryIdResponse) => {
        return response.machinery_id;
      },
    }),
    changeMachinery: build.mutation<number, MachineryChangeRequest>({
      query: ({
        model,
        number,
        machinery_type,
        road_fuel_consumption,
        machinery_id,
        status,
      }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('model', model);
        searchParams.append('number', number);
        searchParams.append('machinery_type', machinery_type.toString());
        searchParams.append(
          'road_fuel_consumption',
          road_fuel_consumption.toString(),
        );
        searchParams.append('machinery_id', machinery_id.toString());
        searchParams.append('status', status.toString());

        return {
          url: `api/change_machinery?${searchParams}`,
          method: 'PUT',
        };
      },
      invalidatesTags: [TagTypes.MACHINERY_TAG],
      transformResponse: (response: MachineryIdResponse) => {
        return response.machinery_id;
      },
    }),
    deleteMachinery: build.mutation<void, number>({
      query: (id) => {
        const searchParams = new URLSearchParams();

        searchParams.append('machinery_id', id.toString());

        return {
          url: `api/delete_machinery?${searchParams}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [TagTypes.MACHINERY_TAG],
    }),
  }),
});

export const {
  useGetMachineryQuery,
  useGetAllMachineryQuery,
  useAddMachineryMutation,
  useChangeMachineryMutation,
  useDeleteMachineryMutation,
} = machineryApi;
