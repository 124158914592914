export const assignmentTrackPopup = (date: string, speed: string) =>
  `<div style="display: flex; justify-content: space-between">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.08301 5.41671H10.9163V7.25004H9.08301V5.41671ZM9.08301 9.08337H10.9163V14.5834H9.08301V9.08337ZM9.99967 0.833374C4.93967 0.833374 0.833008 4.94004 0.833008 10C0.833008 15.06 4.93967 19.1667 9.99967 19.1667C15.0597 19.1667 19.1663 15.06 19.1663 10C19.1663 4.94004 15.0597 0.833374 9.99967 0.833374ZM9.99967 17.3334C5.95717 17.3334 2.66634 14.0425 2.66634 10C2.66634 5.95754 5.95717 2.66671 9.99967 2.66671C14.0422 2.66671 17.333 5.95754 17.333 10C17.333 14.0425 14.0422 17.3334 9.99967 17.3334Z"
        fill="#9EB33F"
      />
    </svg>
    <div style="margin-left: 14px">
      <div style="display: flex; justify-content: space-between; margin-top: 4px">
        <div style="font-size: 14px">
          <p>${date}</p>
          <p>${speed}</p>
        </div>
    
      </div>
    </div>
  </div>`;
