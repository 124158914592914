import * as varColor from 'app/styles/colors';

import { DrawType } from '../model/types';

export const polygonsData = {
  farmland_polygon: {
    id: 'farmland_polygon',
    typeArea: DrawType.farmland_polygon,
    portColor: varColor.darkBlue,
  },
  error_area: {
    id: 'error_area',
    typeArea: DrawType.error_area,
    portColor: varColor.red,
  },
  reprocess_area: {
    id: 'reprocess_area',
    typeArea: DrawType.reprocess_area,
    portColor: varColor.yellow,
  },
  worked_area: {
    id: 'worked_area',
    typeArea: DrawType.worked_area,
    portColor: varColor.green,
  },
};
