import { useTranslation } from 'react-i18next';
import { MachineryTypeDto } from 'entities/catalog';
import { Input } from 'shared/ui';

type Props = {
  machineryTypesList?: Array<MachineryTypeDto>;
};

export const MachineryForm: React.FC<Props> = ({ machineryTypesList }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Input
        select
        label="machineryType"
        name="machinery_type"
        defaultValue={-1}
        defaultMenuItems={{
          value: '-1',
          text: 'wayStatusNone',
        }}
        menuItems={
          machineryTypesList &&
          machineryTypesList.reduce((obj, item) => {
            obj[item.type_id] =
              item[
                `machinery_type_name_${i18n.language}` as keyof Omit<
                  MachineryTypeDto,
                  'type_id'
                >
              ];
            return obj;
          }, {} as { [id: number]: string })
        }
      />
      <Input
        label="model"
        name="model"
        placeholder={`${t('placeholderList.insertModel')}`}
        InputLabelProps={{ shrink: true }}
      />
      <Input
        label="number"
        name="number"
        placeholder={`${t('placeholderList.insertNumber')}`}
        InputLabelProps={{ shrink: true }}
      />
      <Input
        type="number"
        label="plannedFuelConsumption"
        name="road_fuel_consumption"
        placeholder={`${t('placeholderList.insertplannedFuelConsumption')}`}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputProps: { step: 0.1 },
        }}
      />
    </>
  );
};
