import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import { ModalTypes, selectPageSlice, setIsOpenModal } from 'entities/page';
import { selectSessionSlice } from 'entities/session';
import { logoutThunk } from 'features/authentication/logout';
import { Modal } from 'features/modal';
import { Header } from 'features/page';
import {
  DRAWER_WIDTH_MAX,
  DRAWER_WIDTH_MIN,
  HEIGHT_PAGE_WITHOUT_HEADER,
} from 'shared/constant';
import { useAppDispatch, useAppSelector } from 'shared/model';

import { navigationTabsList, navigationTabsLogout } from '../../config';
import { NavigationElement } from '../NavigationElement/NavigationElement';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH_MAX,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderRight: 'none',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  borderRight: 'none',
  width: DRAWER_WIDTH_MIN,
});

const NavigationContainer = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  borderRight: `1px solid ${theme.palette.grey[300]};`,
}));

const NavigationList = styled(List)(({ theme }) => ({
  overflow: 'hidden',
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH_MAX,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MainContent = styled('div')(({ theme }) => ({
  flex: 1,
}));

export const Layout: React.FC = () => {
  const { t } = useTranslation();
  const { isMenuOpen } = useAppSelector(selectSessionSlice);
  const { isOpenModal, modalType } = useAppSelector(selectPageSlice);
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logoutThunk());
    handleCloseModalLogout();
  };

  const handleOpenModalLogout = () => {
    dispatch(setIsOpenModal({ isOpen: true, type: ModalTypes.logout }));
  };

  const handleCloseModalLogout = () => {
    dispatch(setIsOpenModal(false));
  };

  return (
    <>
      <Modal
        open={isOpenModal && modalType === ModalTypes.logout}
        title="logoutTitle"
        onDone={handleLogout}
        onClose={handleCloseModalLogout}
        secondaryText={'cancel'}
        primaryText={'logout'}
      >
        {t('logoutText')}
      </Modal>

      <Header />

      <Box component="main" sx={{ display: 'flex' }}>
        <Drawer
          variant="permanent"
          open={isMenuOpen}
          PaperProps={{
            sx: {
              top: 64,
              '& .MuiList-root': { height: HEIGHT_PAGE_WITHOUT_HEADER },
            },
          }}
        >
          <NavigationContainer>
            <NavigationList>
              {navigationTabsList.map((navigationElement, index) => (
                <NavigationElement
                  key={index}
                  isMenuOpen={isMenuOpen}
                  navigationElement={navigationElement}
                />
              ))}
            </NavigationList>

            <Box>
              <Divider />
              <NavigationElement
                onClick={handleOpenModalLogout}
                isMenuOpen={isMenuOpen}
                navigationElement={navigationTabsLogout}
              />
            </Box>
          </NavigationContainer>
        </Drawer>

        <MainContent>
          <React.Suspense>
            <Outlet />
          </React.Suspense>
        </MainContent>
      </Box>
    </>
  );
};
