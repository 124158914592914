/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CircularProgress, Tooltip, TooltipProps } from '@mui/material';
import Switch, { type SwitchProps } from '@mui/material/Switch';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ palette, shadows }) => ({
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: palette.background.default,
    boxShadow: shadows[1],
  },
  active: {
    backgroundColor: palette.primary.main,
  },
}));

interface Props extends SwitchProps {
  tooltip?: {
    title: TooltipProps['title'];
    placement: TooltipProps['placement'];
  };
  loading?: boolean;
}

export const Switches: React.FC<Props> = React.memo(
  ({ loading, checked, tooltip, ...rest }) => {
    const { classes, cx } = useStyles();

    const Icon = () => (
      <div
        className={cx(
          classes.circle,
          checked && !loading ? classes.active : '',
        )}
      >
        {loading && (
          <CircularProgress size={14} color="secondary" thickness={6} />
        )}
      </div>
    );

    return (
      <Tooltip title={tooltip?.title} placement={tooltip?.placement}>
        <Switch
          checked={checked}
          checkedIcon={<Icon />}
          icon={<Icon />}
          disabled={loading}
          onClick={(e) => {
            e.stopPropagation();
          }}
          inputProps={{ 'aria-label': 'Switch with loading state' }}
          {...rest}
        />
      </Tooltip>
    );
  },
);
