import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
};

export const TitlePage: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{`Farmat | ${t(title)}`}</title>
    </Helmet>
  );
};
