import { combineReducers } from '@reduxjs/toolkit';
import { catalogApi } from 'entities/catalog';
import { employeeApi } from 'entities/employee';
import { farmlandsApi } from 'entities/farmlands';
import { machineryApi } from 'entities/machinery';
import { mapSlice } from 'entities/map';
import { pageSlice } from 'entities/page';
import { sessionApi, sessionSlice } from 'entities/session';
import { systemMessagesSlice } from 'entities/systemMessages';
import { taskAssignmentApi } from 'entities/taskAssignment';
import { tasksApi } from 'entities/tasks';
import { unitsApi } from 'entities/units';
import { baseApi } from 'shared/api';

export const rootReducer = combineReducers({
  [sessionSlice.name]: sessionSlice.reducer,
  [mapSlice.name]: mapSlice.reducer,
  [sessionApi.reducerPath]: sessionApi.reducer,
  [farmlandsApi.reducerPath]: farmlandsApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [taskAssignmentApi.reducerPath]: taskAssignmentApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [machineryApi.reducerPath]: machineryApi.reducer,
  [unitsApi.reducerPath]: unitsApi.reducer,
  [catalogApi.reducerPath]: catalogApi.reducer,
  [pageSlice.name]: pageSlice.reducer,
  [systemMessagesSlice.name]: systemMessagesSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
});
