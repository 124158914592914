import { number, object } from 'yup';

import { validateYupTextField } from '../validateList';

export const unitValidationSchema = object().shape({
  unit_type: number().required('fillField'),
  model: validateYupTextField.required('fillField'),
  number: validateYupTextField.required('fillField'),
  width: number().min(0, 'lessThanZero').required('fillField'),
});
