import { useTranslation } from 'react-i18next';
import {
  LabelDisplayedRowsArgs,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableContainer,
  TableContainerProps,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@mui/material';
import { type Column, type TablePageIdHeader } from 'entities/page';

import { TableCellHead } from '../TableCellHead/TableCellHead';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 100];

type Props<T> = {
  columnData: Array<Column<T>>;
  children?: React.ReactNode;
  pagination?: {
    count: number;
    rowsPerPage: number;
    page: number;
    onPageChange: (_: unknown, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  sx?: SxProps<Theme>;
  tableContainerProps?: TableContainerProps;
};

export const TablePage: React.FC<Props<TablePageIdHeader>> = ({
  columnData,
  children,
  pagination,
  sx,
  tableContainerProps,
}) => {
  const { t } = useTranslation();

  const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) =>
    `${from} – ${to} ${t('from')} ${count}`;

  return (
    <Paper sx={{ height: '100%', minWidth: '100%', ...sx }}>
      <TableContainer
        sx={{
          height: pagination ? '72vh' : 'calc(72vh + 52px)',
          minWidth: '100%',
        }}
        {...tableContainerProps}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnData.map((column) => (
                <TableCellHead key={column.id} column={column} />
              ))}
            </TableRow>
          </TableHead>

          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>

      {pagination && (
        <TablePagination
          labelRowsPerPage=""
          labelDisplayedRows={labelDisplayedRows}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={pagination.onPageChange}
          onRowsPerPageChange={pagination.onRowsPerPageChange}
        />
      )}
    </Paper>
  );
};
