import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { TaskTypeDto } from 'entities/catalog';
import { FarmlandsDto } from 'entities/farmlands';
import { Form, useFormikContext } from 'formik';
import { DateTimePickerCustom, Input } from 'shared/ui';

type Props = {
  disabled: boolean;
  tasksTypesAll?: TaskTypeDto[];
  farmlandsAll?: Omit<FarmlandsDto, 'coordinates' | 'unaccounted_areas'>[];
};

export const TaskForm: React.FC<Props> = ({
  tasksTypesAll,
  farmlandsAll,
  disabled,
}) => {
  const { t, i18n } = useTranslation();
  const { values } = useFormikContext<{
    begin_datetime?: Dayjs;
    end_datetime?: Dayjs;
  }>();

  return (
    <Box
      id="taskForm"
      component={Form}
      sx={{
        maxWidth: 370,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Input
        select
        label="taskType"
        name="task_type"
        placeholder={`${t('placeholderList.insertPassword')}`}
        defaultValue={-1}
        defaultMenuItems={{
          value: '-1',
          text: 'wayStatusNone',
        }}
        menuItems={
          tasksTypesAll &&
          tasksTypesAll.reduce((obj, item) => {
            obj[item.type_id] =
              item?.[
                `task_type_name_${i18n.language}` as keyof Omit<
                  TaskTypeDto,
                  'type_id'
                >
              ];
            return obj;
          }, {} as { [id: number]: string })
        }
        disabled={disabled}
      />
      <DateTimePickerCustom
        label="startDate"
        name="begin_datetime"
        minDate={dayjs().utc()}
        maxDate={
          values.end_datetime ? dayjs(values.end_datetime).utc() : undefined
        }
        disabled={disabled}
      />
      <DateTimePickerCustom
        label="endDate"
        name="end_datetime"
        minDate={dayjs(values.begin_datetime).utc()}
        disabled={disabled}
      />
      <Input
        select
        label="farmland"
        name="farmland_id"
        placeholder={`${t('placeholderList.insertPassword')}`}
        defaultValue={-1}
        defaultMenuItems={{
          value: '-1',
          text: 'wayStatusNone',
        }}
        menuItems={
          farmlandsAll &&
          farmlandsAll.reduce((obj, item) => {
            obj[item.farmland_id] = item?.farmland_name;
            return obj;
          }, {} as { [id: number]: string })
        }
        disabled={disabled}
      />
    </Box>
  );
};
