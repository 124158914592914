/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import enTranslation from './locales/en.json';
import ruTranslation from './locales/ru.json';

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('language') ?? 'en',
  resources: {
    ru: {
      translation: ruTranslation,
    },
    en: {
      translation: enTranslation,
    },
  },
});

export default function withLocalization(
  WrappedComponent: React.ComponentType,
) {
  return function WithLocalization(props: any) {
    return <WrappedComponent {...props} />;
  };
}
