import { createApi } from '@reduxjs/toolkit/query/react';

import customFetchBase from './customFetchBase';
import { TagTypes } from './tags';

export const baseApi = createApi({
  tagTypes: [
    TagTypes.SESSION_TAG,
    TagTypes.FARMLAND_TAG,
    TagTypes.TASK_TAG,
    TagTypes.TASK_ASSIGNMENT_TAG,
    TagTypes.EMPLOYEE_TAG,
    TagTypes.MACHINERY_TAG,
    TagTypes.UNIT_TAG,
  ],
  reducerPath: 'api',
  baseQuery: customFetchBase,
  endpoints: () => ({}),
});
