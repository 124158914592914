import * as turf from '@turf/turf';

export function getMultiPolygonWithProp(
  id: string,
  payload: turf.Position[][],
  prop: { [key: string]: string },
) {
  const polygon = turf.multiPolygon([payload], prop);

  return { ...polygon, id };
}
