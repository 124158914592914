import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Box,
  CardMedia,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { LanguageSwitcher } from 'entities/page';
import {
  selectSessionSlice,
  setIsMenuOpen,
  useGetOrganizationQuery,
} from 'entities/session';
import LogoSvg from 'shared/assets/logo.png';
import { HEADER_HEIGHT } from 'shared/constant';
import { useAppDispatch, useAppSelector } from 'shared/model';

type Props = {
  hideMenu?: boolean;
};

export const Header: React.FC<Props> = ({ hideMenu }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { isMenuOpen, isAuthorized } = useAppSelector(selectSessionSlice);

  const { data: organizationData } = useGetOrganizationQuery(undefined, {
    skip: !isAuthorized,
  });

  const handleDrawerOpen = () => {
    dispatch(setIsMenuOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(setIsMenuOpen(false));
  };

  return (
    <Box
      component="header"
      sx={{
        width: '100%',
        height: HEADER_HEIGHT,
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: ({ spacing }) => spacing(0, 2, 0, 1),
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {!hideMenu && (
          <IconButton
            onClick={isMenuOpen ? handleDrawerClose : handleDrawerOpen}
          >
            {isMenuOpen ? (
              <ChevronLeftIcon htmlColor="white" />
            ) : (
              <MenuIcon htmlColor="white" />
            )}
          </IconButton>
        )}

        <CardMedia
          component="img"
          src={LogoSvg}
          alt="farmat logo"
          sx={{ width: 90, height: 50, ml: 4, userSelect: 'none' }}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2 }}>
        {organizationData ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Typography
              color={theme.palette.common.white}
              variant="body2"
            >{`${organizationData.organization_name}, ${organizationData.city},  ${organizationData.address}`}</Typography>
            <Avatar sx={{ width: 20, height: 20 }} />
          </Box>
        ) : null}

        <LanguageSwitcher />
      </Box>
    </Box>
  );
};
