import { useTranslation } from 'react-i18next';
import { Input } from 'shared/ui';

export const EmployeeChangeNameForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        label="name"
        name="employee_name"
        placeholder={`${t('placeholderList.insertName')}`}
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
};
