import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import { NavigationTab } from '../../types/navigationTab';

interface Props {
  isMenuOpen: boolean;
  navigationElement: NavigationTab;
  onClick?: () => void;
}

export const NavigationElement: React.FC<Props> = ({
  isMenuOpen,
  navigationElement,
  onClick,
}) => {
  const { link, text, icon: Icon } = navigationElement;
  const { t } = useTranslation();
  const buttonProps = link
    ? {
        to: link,
        component: NavLink,
        onClick,
      }
    : {
        onClick,
      };

  return (
    <Tooltip
      key={text}
      title={t(text)}
      placement="right"
      disableHoverListener={isMenuOpen}
    >
      <ListItemButton {...buttonProps}>
        <ListItemIcon>
          <Icon width="24" height="24" />
        </ListItemIcon>

        <ListItemText primary={t(text)} />
      </ListItemButton>
    </Tooltip>
  );
};
