import { number, object } from 'yup';

import { validateYupTextField } from '../validateList';

export const machineryValidationSchema = object().shape({
  machinery_type: number().required('fillField'),
  model: validateYupTextField.required('fillField'),
  number: validateYupTextField.required('fillField'),
  road_fuel_consumption: number().min(0, 'lessThanZero').required('fillField'),
});
