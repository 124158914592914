/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogContentProps,
  type DialogProps,
} from '@mui/material';

import { ModalTitle } from '../ModalTitle/ModalTitle';

interface Props {
  open: boolean;
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
  onDone?: () => void;
  onBack?: { handleBack: () => void; disabled: boolean };
  DialogContentProps?: DialogContentProps;
  hideDialogActions?: boolean;
  primaryText?: string | null;
  secondaryText?: string | null;
  isDisabledClose?: boolean;
  isDisabledDone?: boolean;
  isDangerous?: boolean;
  isErrorMessage?: boolean;
  isSubmitting?: boolean;
  formId?: string;
}

export const Modal: React.FC<Props & DialogProps> = ({
  open,
  title = '\u00A0',
  onClose,
  onDone,
  onBack,
  children,
  DialogContentProps,
  hideDialogActions,
  primaryText = '\u00A0',
  secondaryText = '\u00A0',
  isDisabledClose,
  isDisabledDone,
  isDangerous,
  isErrorMessage,
  isSubmitting,
  formId,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      {...rest}
    >
      <ModalTitle
        id="customized-dialog-title"
        isErrorMessage={isErrorMessage}
        onClose={onClose}
      >
        {t(title)}
      </ModalTitle>

      <DialogContent dividers {...DialogContentProps}>
        {children}
      </DialogContent>

      {!hideDialogActions && (
        <DialogActions
          sx={{
            display: 'flex',
            padding: 2,
            justifyContent: onBack ? 'space-between' : 'flex-end',
          }}
        >
          {onBack && (
            <Button
              variant="contained"
              onClick={onBack.handleBack}
              color="neutral"
              disabled={onBack.disabled}
            >
              {'back'}
            </Button>
          )}

          <Box sx={{ display: 'flex', gap: 2 }}>
            {!isErrorMessage && (
              <Button
                form={formId}
                type={formId ? 'submit' : 'button'}
                variant="contained"
                onClick={onDone}
                color="primary"
                sx={
                  isDangerous
                    ? { backgroundColor: (theme) => theme.palette.error.main }
                    : {}
                }
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : null
                }
                disabled={isDisabledDone}
              >
                {primaryText && t(primaryText)}
              </Button>
            )}
            <Button
              variant="contained"
              onClick={onClose}
              color="neutral"
              disabled={isDisabledClose}
            >
              {secondaryText && t(secondaryText)}
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};
