import { useTranslation } from 'react-i18next';
import { TableCell as TableCellMui } from '@mui/material';
import { type Column, type TablePageIdHeader } from 'entities/page';

type Props = {
  column: Column<TablePageIdHeader>;
};

export const TableCellHead: React.FC<Props> = ({ column }) => {
  const { minWidth, maxWidth, width, headerName, subtitle } = column;
  const { t } = useTranslation();

  return (
    <TableCellMui
      sx={{
        minWidth,
        maxWidth,
        width,
        whiteSpace: 'nowrap',
        pt: 1,
        pb: 1,
        fontSize: 12,
        lineHeight: '1rem',
      }}
    >
      {t(headerName)}
      <br />
      {subtitle && t(subtitle)}
    </TableCellMui>
  );
};
