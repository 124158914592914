import { useTranslation } from 'react-i18next';
import { Input } from 'shared/ui';

export const EmployeeAddForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        label="loginText"
        name="employee_login"
        placeholder={`${t('placeholderList.insertLogin')}`}
        InputLabelProps={{ shrink: true }}
      />
      <Input
        label="name"
        name="employee_name"
        placeholder={`${t('placeholderList.insertName')}`}
        InputLabelProps={{ shrink: true }}
      />
      <Input
        label="password"
        name="password"
        placeholder={`${t('placeholderList.insertPassword')}`}
        InputLabelProps={{ shrink: true }}
      />
      <Input
        label="confirmPassword"
        name="confirmPassword"
        placeholder={`${t('placeholderList.insertPassword')}`}
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
};
