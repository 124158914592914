import { SystemMessageSnackBar } from 'features/systemMessages';
import { SnackbarProvider as Provider } from 'notistack';

type Props = {
  children: React.ReactNode;
};

export const SnackbarProvider: React.FC<Props> = ({ children }) => {
  return (
    <Provider autoHideDuration={5000}>
      <SystemMessageSnackBar />
      {children}
    </Provider>
  );
};
