import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { baseApi, TagTypes } from 'shared/api';

import { mapSession } from '../lib/mapSession';

import {
  type LoginFromParams,
  LoginRejectDto,
  LoginResponseDto,
  OrganizationBboxRequest,
  OrganizationResponse,
  SessionDto,
} from './types';

export const sessionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<SessionDto | LoginRejectDto, LoginFromParams>({
      query: ({ email, password }) => ({
        url: 'api/user_login',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${email}:${password}`)}`,
        },
        providesTags: [TagTypes.SESSION_TAG],
      }),
      transformResponse: (
        response: LoginResponseDto | LoginRejectDto,
        meta,
        arg,
      ) => {
        if (response.status) {
          return mapSession(response as LoginResponseDto, arg);
        }
        return response as LoginRejectDto;
      },
    }),
    getOrganization: build.query<
      OrganizationResponse['organization_data'],
      void
    >({
      query: () => ({
        url: 'api/get_organization_data',
        method: 'GET',
      }),
      transformResponse: (response: OrganizationResponse) => {
        return response.organization_data;
      },
    }),
    getCoordinatesByPostcode: build.query<
      mapboxgl.LngLatBoundsLike,
      OrganizationBboxRequest
    >({
      query: ({ postcode }) => ({
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${postcode}.json?types=postcode&access_token=${
          process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || window.mapboxTokenString
        }`,
        method: 'GET',
      }),
      transformResponse: (
        response: FeatureCollection<Geometry, GeoJsonProperties>,
      ) => {
        return response.features[0].bbox as mapboxgl.LngLatBoundsLike;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useGetOrganizationQuery,
  useGetCoordinatesByPostcodeQuery,
} = sessionApi;
