import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLoginMutation } from 'entities/session';
import { Form, Formik } from 'formik';
import { HEIGHT_PAGE_WITHOUT_HEADER } from 'shared/constant';
import { loginValidationSchema } from 'shared/lib';
import { Input } from 'shared/ui';

const Wrapper = styled('main')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: HEIGHT_PAGE_WITHOUT_HEADER,
});

const Container = styled(Paper)({
  width: 408,
  padding: 16,
});

const initialValues = {
  email: '',
  password: '',
};

export const LoginForm: React.FC<{ onComplete: () => void }> = ({
  onComplete,
}) => {
  const { t } = useTranslation();
  const [loginUser, { isLoading, isSuccess }] = useLoginMutation();

  useEffect(() => {
    if (isSuccess) {
      onComplete();
    }
  }, [onComplete, isSuccess]);

  return (
    <Wrapper>
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={loginValidationSchema}
          onSubmit={loginUser}
        >
          {() => (
            <Form>
              <Typography variant="h5">{t('authorization')}</Typography>
              <Typography
                variant="body2"
                sx={{ mb: 4, color: ({ palette }) => palette.text.light }}
              >
                {t('pleaseLogIn')}
              </Typography>
              <Input
                label="email"
                name="email"
                placeholder={`${t('placeholderList.insertEmail')}`}
                InputLabelProps={{ shrink: true }}
              />
              <Input
                label="password"
                name="password"
                type="password"
                placeholder={`${t('placeholderList.insertPassword')}`}
                InputLabelProps={{ shrink: true }}
              />
              <Button
                fullWidth
                sx={{ marginTop: 0, marginBottom: 1 }}
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? `${t('loading')}...` : t('login')}
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </Wrapper>
  );
};
