import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectSessionSlice } from 'entities/session';
import { useAppSelector } from 'shared/model';

export const RequireUser: React.FC = () => {
  const location = useLocation();

  const { isAuthorized } = useAppSelector(selectSessionSlice);

  return isAuthorized ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
