export enum ModalTypes {
  farmlandsRemove = 'farmlandsRemove',
  taskRemove = 'taskRemove',
  assignmentAdd = 'taskAssignmentAdd',
  assignmentRemove = 'taskAssignmentRemove',
  unitAdd = 'unitAdd',
  unitRemove = 'unitRemove',
  machineryAdd = 'machineryAdd',
  machineryRemove = 'machineryRemove',
  employeeAdd = 'employeeAdd',
  employeeNameChange = 'employeeNameChange',
  employeePasswordChange = 'employeePasswordChange',
  employeeRemove = 'employeeRemove',
  logout = 'logout',
}
