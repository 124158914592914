/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { Box, FormHelperText, TextFieldProps } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';

type Props = {
  name: string;
  label: string;
  hidehelperText?: boolean;
  helperText?: string;
  disableFuture?: DateTimePickerProps<Dayjs>['disableFuture'];
  maxDate?: DateTimePickerProps<Dayjs>['maxDate'];
  minDate?: DateTimePickerProps<Dayjs>['minDate'];
};

export const DateTimePickerCustom: React.FC<TextFieldProps & Props> = ({
  name,
  label,
  hidehelperText = false,
  helperText,
  disableFuture,
  minDate,
  maxDate,
  ...rest
}) => {
  const { values, errors, touched, setFieldValue, handleBlur } =
    useFormikContext<any>();
  const { t } = useTranslation();

  const isError = !!errors[name] && touched[name] ? true : false;

  const getError = (key: string) =>
    !!errors[key] && touched[key] ? t(`validationList.${errors[key]}`) : '';

  const handleChange = (value: Dayjs | null) => {
    setFieldValue(name, value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DateTimePicker
        disableFuture={disableFuture}
        minDate={minDate}
        maxDate={maxDate}
        label={t(label)}
        format="DD-MM-YYYY HH:mm"
        value={values[name]}
        onChange={handleChange}
        views={['year', 'month', 'day', 'hours', 'minutes']}
        sx={{ width: '100%' }}
        slotProps={{
          textField: {
            InputLabelProps: { shrink: true },
            name,
            onBlur: handleBlur,
            error: isError,
            ...rest,
          },
        }}
        viewRenderers={{
          hours: null,
          minutes: null,
          seconds: null,
        }}
      />
      {!hidehelperText && (
        <FormHelperText error>{getError(name)}</FormHelperText>
      )}
    </Box>
  );
};
