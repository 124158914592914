import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalTypes } from './types';

export type PageSliceState = {
  modalType: ModalTypes | null;
  isOpenModal: boolean;
  activeTabIndex: number;
  activeData: unknown;
};

const initialState: PageSliceState = {
  modalType: null,
  isOpenModal: false,
  activeTabIndex: 0,
  activeData: null,
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setIsOpenModal: (
      state,
      {
        payload,
      }: PayloadAction<boolean | { isOpen: boolean; type: ModalTypes }>,
    ) => {
      if (typeof payload === 'boolean') {
        state.isOpenModal = payload;
        state.modalType = null;
      } else {
        state.isOpenModal = payload.isOpen;
        state.modalType = payload.type;
      }
    },
    setActiveTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.activeTabIndex = payload;
    },
    setActiveData: (state, { payload }: PayloadAction<unknown>) => {
      state.activeData = payload;
    },
  },
});

export const selectPageSlice = (state: RootState) => state.page;

export const { setIsOpenModal, setActiveTabIndex, setActiveData } =
  pageSlice.actions;
