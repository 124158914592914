import * as turf from '@turf/turf';

export function getBounds(payload: turf.Position[]) {
  const polygonFeature = turf.polygon([payload]);
  // Calculate the bounding box of the polygon
  const bbox = turf.bbox(polygonFeature);
  const bounds: mapboxgl.LngLatBoundsLike = [
    [bbox[0], bbox[1]],
    [bbox[2], bbox[3]],
  ];

  return bounds;
}
