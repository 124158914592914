export const lightBlue = '#5791E8';
export const transparentBlue = 'rgba(87, 145, 232, 0.64)';
export const text = 'rgba(0, 0, 0, 0.87)';
export const newFrameGrid = '#4B6172';
export const grey2 = '#1F2329';
export const grey3 = '#3D4149';
export const grey4 = '#262B33';
export const grey5 = '#474B54';
export const grey6 = '#464A52';
export const grey7 = '#2A2D34';
export const purple = '#8678FF';
export const gradient = 'linear-gradient(75deg, #5791E8, #4E339B)';

export const white = '#fff';
export const darkBlack = '#000';
export const lightBlack = '#00000099';
export const lightGreen = '#9EB33F';
export const yellow = '#FAFF00';
export const green = '#008000';
export const lightGrey = '#BDBDBDB2';
export const grey = '#a0a0a0b2';
export const blackGrey = '#182628';
export const darkBlue = '#4285F4';
export const mediumAquamarine = '#46D8CF';
export const red = '#ff0000';
