/* eslint-disable react/jsx-props-no-spreading */
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, DialogTitle, Typography } from '@mui/material';

interface DialogTitleProps {
  id: string;
  onClose: () => void;
  isErrorMessage?: boolean;
  children?: React.ReactNode;
}

export function ModalTitle(props: DialogTitleProps) {
  const { children, onClose, isErrorMessage, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, pb: 1, pl: 2, pt: 1, pr: 1, display: 'flex' }}
      {...other}
    >
      {isErrorMessage && (
        <ErrorOutlineIcon sx={{ mr: 1 }} fontSize="small" color="error" />
      )}

      <Typography variant="body2" sx={{ pr: '70px' }}>
        {children}
      </Typography>
      {onClose ? (
        <Button
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={{
            minWidth: 60,
            position: 'absolute',
            right: 0,
            top: 0,
            borderTopLeftRadius: 'initial',
            borderBottomLeftRadius: 'initial',
            borderBottomRightRadius: 'initial',
            color: (theme) => theme.palette.grey[500],
            '&:hover': {
              color: (theme) => theme.palette.common.white,
              backgroundColor: (theme) => theme.palette.error.main,
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </Button>
      ) : null}
    </DialogTitle>
  );
}
