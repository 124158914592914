import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Example
// https://some-site.com/name/edit/1
//
// pathnameArr = ['', 'name', 'edit', '1']
// You can get also third parameter - '1'

export const useGetParamPageId = () => {
  const { pathname } = useLocation();
  const idRef = useRef<{ id: number; isEditPage: boolean }>();
  const isEditPage = false;
  const pathnameArr = pathname.split('/');
  const id = pathnameArr[3];

  if (pathnameArr[2] === 'edit') {
    idRef.current = { id: +id, isEditPage: true };
  } else {
    idRef.current = { id: +id, isEditPage };
  }

  return idRef.current;
};
