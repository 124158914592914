export const getMidpoint = (data: mapboxgl.LngLatBoundsLike) => {
  if ((data as [mapboxgl.LngLatLike, mapboxgl.LngLatLike]).length === 2) {
    const p1 = (data as [[number, number], [number, number]])[0];
    const p2 = (data as [[number, number], [number, number]])[1];
    const dist = [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2];
    return dist;
  } else {
    const p1 = [
      (data as [number, number, number, number])[0],
      (data as [number, number, number, number])[1],
    ];
    const p2 = [
      (data as [number, number, number, number])[2],
      (data as [number, number, number, number])[3],
    ];
    const dist = [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2];
    return dist;
  }
};
