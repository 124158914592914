import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export const LanguageSwitcher: React.FC = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event: SelectChangeEvent) => {
    const value = event.target.value;

    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
  };

  const options = [
    { value: 'en', label: 'language.en' },
    { value: 'ru', label: 'language.ru' },
  ];

  return (
    <Select
      defaultValue={localStorage.getItem('language') ?? 'en'}
      onChange={changeLanguage}
      sx={{
        color: 'white',
        '& .MuiSelect-icon': {
          color: 'white',
        },
      }}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {t(label)}
        </MenuItem>
      ))}
    </Select>
  );
};
