import { number, object } from 'yup';

import { validateYupTextField } from '../validateList';

export const taskValidationSchema = object().shape({
  begin_datetime: validateYupTextField.required('fillField'),
  end_datetime: validateYupTextField.required('fillField'),
  farmland_id: number().required('fillField'),
  task_type: number().required('fillField'),
});
