import { FarmlandsPolygons } from 'entities/farmlands';
import { getMultiPolygonWithProp } from 'shared/lib';

import { polygonsData } from './polygonsData';

export const getJoinFarmlandPolygons = ({
  farmland_polygon,
  unaccounted_areas,
  error_area,
  reprocess_area,
  worked_area,
}: FarmlandsPolygons) => {
  const features = [];
  const featuresFarmland = getMultiPolygonWithProp(
    polygonsData.farmland_polygon.id,
    [farmland_polygon, ...unaccounted_areas],
    {
      typeArea: polygonsData.farmland_polygon.typeArea,
      portColor: polygonsData.farmland_polygon.portColor,
    },
  );
  features.push(featuresFarmland);

  if (worked_area) {
    const featuresWorkedArea = worked_area.map((area, index) =>
      getMultiPolygonWithProp(`${polygonsData.worked_area.id}_${index}`, area, {
        typeArea: polygonsData.worked_area.typeArea,
        portColor: polygonsData.worked_area.portColor,
      }),
    );
    features.push(...featuresWorkedArea);
  }

  if (reprocess_area) {
    const featuresReprocessArea = reprocess_area.map((area, index) =>
      getMultiPolygonWithProp(
        `${polygonsData.reprocess_area.id}_${index}`,
        area,
        {
          typeArea: polygonsData.reprocess_area.typeArea,
          portColor: polygonsData.reprocess_area.portColor,
        },
      ),
    );
    features.push(...featuresReprocessArea);
  }

  if (error_area) {
    const featuresErrorArea = error_area.map((area, index) =>
      getMultiPolygonWithProp(`${polygonsData.error_area.id}_${index}`, area, {
        typeArea: polygonsData.error_area.typeArea,
        portColor: polygonsData.error_area.portColor,
      }),
    );
    features.push(...featuresErrorArea);
  }
  return features;
};
