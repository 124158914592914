import { Box, Skeleton } from '@mui/material';
import { HEIGHT_PAGE_WITHOUT_HEADER } from 'shared/constant';

import { PageContainer } from '../PageContainer/PageContainer';

export const SkeletonHalfPage: React.FC = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <PageContainer sx={{ width: 470, flex: 'none' }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={44}
          sx={{ borderRadius: 0.5, mb: 4 }}
        />
        <Skeleton
          variant="rectangular"
          height={150}
          sx={{ borderRadius: 0.5, mb: 7.5 }}
        />
        <Skeleton
          variant="rectangular"
          height={76}
          sx={{ borderRadius: 0.5, mb: 4 }}
        />
        <Skeleton
          variant="rectangular"
          height={76}
          sx={{ borderRadius: 0.5 }}
        />
      </PageContainer>

      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: HEIGHT_PAGE_WITHOUT_HEADER,
          borderRadius: 0,
          transform: 'none',
        }}
      />
    </Box>
  );
};
