import type {
  LoginFromParams,
  LoginResponseDto,
  SessionDto,
} from '../api/types';

export function mapSession(
  dto: LoginResponseDto,
  { email }: LoginFromParams,
): SessionDto {
  return {
    accessToken: dto.access_token,
    refreshToken: dto.refresh_token,
    userId: dto.user_id,
    status: dto.status,
    email,
  };
}
