import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/appStore';
import { clearMapData } from 'entities/map';
import { clearSessionData, sessionApi } from 'entities/session';
import { TagTypes } from 'shared/api';
import { wait } from 'shared/lib';

export const logoutThunk = createAsyncThunk<void, void, { state: RootState }>(
  'authentication/logout',
  async (_: unknown, { dispatch }) => {
    dispatch(clearSessionData());
    dispatch(clearMapData());

    // Wait 10ms to invalidateTags in next event loop tick.
    // Otherwise after invalidate related requests with SESSION_TAG
    // will be started, but isAuthorized will still be equal to true
    await wait(10);

    // 👇 ATTENTION: This line clear all baseApi state instead of sessionApi
    // dispatch(sessionApi.util.resetApiState())
    dispatch(sessionApi.util.invalidateTags([TagTypes.SESSION_TAG]));
    // dispatch(camerasApi.util.invalidateTags([CAMERAS_TAG]));
  },
);
