import { baseApi, TagTypes } from 'shared/api';

import type {
  UnitAddRequest,
  UnitChangeRequest,
  UnitGetResponse,
  UnitIdResponse,
  UnitsGetResponse,
} from './types';

export const unitsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUnit: build.query<UnitGetResponse['unit'], number>({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('unit_id', id.toString());

        return {
          url: `api/get_unit?${searchParams}`,
          method: 'GET',
        };
      },
      providesTags: (result, error, arg) => [
        { type: TagTypes.UNIT_TAG, id: arg },
      ],
      transformResponse: (response: UnitGetResponse) => {
        return response.unit;
      },
    }),
    getAllUnits: build.query<UnitsGetResponse['units'], void>({
      query: () => ({
        url: 'api/get_all_units',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ unit_id }) => ({
                type: TagTypes.UNIT_TAG,
                id: unit_id,
              })),
              TagTypes.UNIT_TAG,
            ]
          : [TagTypes.UNIT_TAG],
      transformResponse: (response: UnitsGetResponse) => {
        return response.units;
      },
    }),
    addUnit: build.mutation<number, UnitAddRequest>({
      query: ({ model, number, unit_type, width }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('model', model);
        searchParams.append('number', number);
        searchParams.append('unit_type', unit_type.toString());
        searchParams.append('width', width.toString());

        return {
          url: `api/add_unit?${searchParams}`,
          method: 'POST',
        };
      },
      invalidatesTags: [TagTypes.UNIT_TAG],
      transformResponse: (response: UnitIdResponse) => {
        return response.unit_id;
      },
    }),
    changeUnit: build.mutation<number, UnitChangeRequest>({
      query: ({ model, number, unit_type, width, unit_id, status }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('model', model);
        searchParams.append('number', number);
        searchParams.append('unit_type', unit_type.toString());
        searchParams.append('width', width.toString());
        searchParams.append('unit_id', unit_id.toString());
        searchParams.append('status', status.toString());

        return {
          url: `api/change_unit?${searchParams}`,
          method: 'PUT',
        };
      },
      invalidatesTags: [TagTypes.UNIT_TAG],
      transformResponse: (response: UnitIdResponse) => {
        return response.unit_id;
      },
    }),
    deleteUnit: build.mutation<void, number>({
      query: (id) => {
        const searchParams = new URLSearchParams();

        searchParams.append('unit_id', id.toString());

        return { url: `api/delete_unit?${searchParams}`, method: 'DELETE' };
      },
      invalidatesTags: [TagTypes.UNIT_TAG],
    }),
  }),
});

export const {
  useGetUnitQuery,
  useGetAllUnitsQuery,
  useAddUnitMutation,
  useChangeUnitMutation,
  useDeleteUnitMutation,
} = unitsApi;
