import { baseApi } from 'shared/api';

import type {
  CountryTypeResponse,
  FarmlandsTypesResponse,
  MachineryTypesResponse,
  TasksTypesResponse,
  UnitTypesResponse,
} from './types';

export const catalogApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFarmlandsTypesList: build.query<
      FarmlandsTypesResponse['farmlands_types'],
      void
    >({
      query: () => ({
        url: 'api/get_farmlands_types_list',
        method: 'GET',
      }),
      transformResponse: (response: FarmlandsTypesResponse) => {
        return response.farmlands_types;
      },
    }),
    getMachineryTypesList: build.query<
      MachineryTypesResponse['machinery_types'],
      void
    >({
      query: () => ({
        url: 'api/get_machinery_types_list',
        method: 'GET',
      }),
      transformResponse: (response: MachineryTypesResponse) => {
        return response.machinery_types;
      },
    }),
    getTasksTypesList: build.query<TasksTypesResponse['tasks_types'], void>({
      query: () => ({
        url: 'api/get_tasks_types_list',
        method: 'GET',
      }),
      transformResponse: (response: TasksTypesResponse) => {
        return response.tasks_types;
      },
    }),
    getUnitTypesList: build.query<UnitTypesResponse['unit_types'], void>({
      query: () => ({
        url: 'api/get_unit_types_list',
        method: 'GET',
      }),
      transformResponse: (response: UnitTypesResponse) => {
        return response.unit_types;
      },
    }),
    getCountriesList: build.query<CountryTypeResponse['countries'], void>({
      query: () => ({
        url: 'api/get_countries_list',
        method: 'GET',
      }),
      transformResponse: (response: CountryTypeResponse) => {
        return response.countries;
      },
    }),
  }),
});

export const {
  useGetFarmlandsTypesListQuery,
  useGetMachineryTypesListQuery,
  useGetTasksTypesListQuery,
  useGetUnitTypesListQuery,
  useGetCountriesListQuery,
} = catalogApi;
