import { object } from 'yup';

import {
  validateYupConfirmPassword,
  validateYupPassword,
  validateYupTextField,
} from '../validateList';

export const employeeAddValidationSchema = object().shape({
  employee_login: validateYupTextField.required('fillField'),
  employee_name: validateYupTextField.required('fillField'),
  password: validateYupPassword,
  confirmPassword: validateYupConfirmPassword,
});
