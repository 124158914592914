import { baseApi, TagTypes } from 'shared/api';

import type {
  FarmlandGetResponse,
  FarmlandsAddRequest,
  FarmlandsAddResponse,
  FarmlandsChangeRequest,
  FarmlandsGetResponse,
} from './types';

export const farmlandsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFarmland: build.query<FarmlandGetResponse['farmland'], number>({
      query: (id) => {
        const searchParams = new URLSearchParams();
        searchParams.append('farmland_id', id.toString());

        return {
          url: `api/get_farmland?${searchParams}`,
          method: 'GET',
        };
      },
      providesTags: (result, error, arg) => [
        { type: TagTypes.FARMLAND_TAG, id: arg },
      ],
      transformResponse: (response: FarmlandGetResponse) => {
        return response.farmland;
      },
    }),
    getAllFarmlands: build.query<FarmlandsGetResponse['farmlands'], void>({
      query: () => ({
        url: 'api/get_all_farmlands',
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ farmland_id }) => ({
                type: TagTypes.FARMLAND_TAG,
                id: farmland_id,
              })),
              TagTypes.FARMLAND_TAG,
            ]
          : [TagTypes.FARMLAND_TAG],
      transformResponse: (response: FarmlandsGetResponse) => {
        return response.farmlands;
      },
    }),
    addFarmland: build.mutation<FarmlandsAddResponse, FarmlandsAddRequest>({
      query: ({
        farmland_name,
        farmland_type,
        farmland_polygon,
        unaccounted_areas,
      }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('farmland_name', farmland_name);
        searchParams.append('farmland_type', farmland_type.toString());

        return {
          url: `api/add_farmland?${searchParams}`,
          method: 'POST',
          body: { farmland_polygon, unaccounted_areas },
        };
      },
      invalidatesTags: [TagTypes.FARMLAND_TAG],
    }),
    changeFarmland: build.mutation<ResponseBase, FarmlandsChangeRequest>({
      query: ({
        farmland_id,
        farmland_name,
        farmland_type,
        farmland_polygon,
        unaccounted_areas,
      }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('farmland_id', farmland_id.toString());
        searchParams.append('farmland_name', farmland_name);
        searchParams.append('farmland_type', farmland_type.toString());

        return {
          url: `api/change_farmland?${searchParams}`,
          method: 'PUT',
          body: { farmland_polygon, unaccounted_areas },
        };
      },
      invalidatesTags: [TagTypes.FARMLAND_TAG],
    }),
    deleteFarmland: build.mutation<void, number>({
      query: (id) => {
        const searchParams = new URLSearchParams();

        searchParams.append('farmland_id', id.toString());

        return { url: `api/delete_farmland?${searchParams}`, method: 'DELETE' };
      },
      invalidatesTags: [TagTypes.FARMLAND_TAG],
    }),
  }),
});

export const {
  useGetFarmlandQuery,
  useGetAllFarmlandsQuery,
  useAddFarmlandMutation,
  useDeleteFarmlandMutation,
  useChangeFarmlandMutation,
} = farmlandsApi;
