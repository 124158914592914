import { object } from 'yup';

import {
  validateYupConfirmPassword,
  validateYupPassword,
} from '../validateList';

export const employeeChangePasswordValidationSchema = object().shape({
  password: validateYupPassword,
  confirmPassword: validateYupConfirmPassword,
});
