import AgricultureIcon from '@mui/icons-material/Agriculture';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GrassIcon from '@mui/icons-material/Grass';
import PeopleIcon from '@mui/icons-material/People';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { ReactComponent as UnitsSvg } from 'shared/assets/icons/units.svg';

import { NavigationTab } from './types/navigationTab';

export const navigationTabsList: NavigationTab[] = [
  {
    text: 'tasks',
    link: '/tasks',
    icon: PlaylistAddCheckIcon,
  },
  {
    text: 'employees',
    link: '/employees',
    icon: PeopleIcon,
  },
  {
    text: 'machinery',
    link: '/machinery',
    icon: AgricultureIcon,
  },
  {
    text: 'units',
    link: '/units',
    icon: UnitsSvg,
  },
  {
    text: 'farmlands',
    link: '/farmlands ',
    icon: GrassIcon,
  },
];

export const navigationTabsLogout: NavigationTab = {
  text: 'logout',
  icon: ExitToAppIcon,
};
