export const PAGE_INIT = 0;
export const ROWS_PER_PAGE_INIT = 10;
export const DRAWER_WIDTH_MAX = 256;
export const DRAWER_WIDTH_MIN = 54;
export const HEADER_HEIGHT = 64;
export const HEIGHT_PAGE_WITHOUT_HEADER = `calc(100vh - ${HEADER_HEIGHT}px)`;
export const CHIP_TASK_STATUS_DATA = [
  'chip.assigned',
  'chip.inProgress',
  'chip.completed',
];
export const CHIP_TASK_STATUS_COLORS = ['gray', 'red', 'green'];
export const CHIP_ASSIGNMENT_STATUS_DATA = [
  'chip.assigned',
  'chip.transitToWork',
  'chip.inProgress',
  'chip.paused',
  'chip.transitToBase',
  'chip.completed',
];
export const CHIP_ASSIGNMENT_STATUS_COLORS = [
  'gray',
  'blue',
  'red',
  'orange',
  'blue',
  'green',
];
