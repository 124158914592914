import {
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

export const queryLoger = (
  result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
) => {
  console.groupCollapsed(
    `%c${result.meta?.request.method}`,
    'color: green',
    result.meta?.response?.status,
    result.meta?.request.url,
  );
  console.log(result.meta?.request);
  console.log(result.meta?.response);
  console.log(result.data);
  console.groupEnd();
};
