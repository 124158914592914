import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sessionApi } from '../api/sessionApi';
import { SessionDto } from '../api/types';

export type SessionSliceState =
  | {
      isAuthorized: true;
      isMenuOpen: boolean;
      accessToken: string;
      refreshToken: string;
      email: string;
      userId: string;
    }
  | {
      isAuthorized: false;
      isMenuOpen: boolean;
      accessToken?: string;
      refreshToken?: string;
      email?: string;
      userId?: string;
    };

const initialState: SessionSliceState = {
  isAuthorized: false,
  isMenuOpen: false,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setIsMenuOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isMenuOpen = payload;
    },
    setAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
    clearSessionData: (state) => {
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.email = undefined;
      state.userId = undefined;
      state.isAuthorized = false;
      state.isMenuOpen = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      sessionApi.endpoints.login.matchFulfilled,
      (state: SessionSliceState, { payload }) => {
        if (payload.status) {
          const { accessToken, refreshToken, email, userId } =
            payload as SessionDto;

          state.isAuthorized = true;

          if (state.isAuthorized) {
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
            state.email = email;
            state.userId = userId;
          }
        }
      },
    );
  },
});

export const selectSessionSlice = (state: RootState) => state.session;

export const { clearSessionData, setAccessToken, setIsMenuOpen } =
  sessionSlice.actions;
