import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { setAccessToken } from 'entities/session';
import { showNotificationSnackBar } from 'entities/systemMessages';
import { logoutThunk } from 'features/authentication/logout/model/logout';
import i18next from 'i18next';
import { queryLoger } from 'shared/lib';

const address = process.env.REACT_APP_ADDRESS || window.urlString;
const port = process.env.REACT_APP_PORT || window.portString;
const protocol = process.env.REACT_APP_PROTOCOL || window.protocolString;

// Create a new mutex
const mutex = new Mutex();
const baseUrl = `${protocol}://${address}:${port}`;

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { accessToken } = (api.getState() as RootState).session;

  const baseQuery = fetchBaseQuery({
    baseUrl,
  });

  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let result = await baseQuery(
    accessToken
      ? {
          ...(args as FetchArgs),
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      : args,
    api,
    extraOptions,
  );

  if (
    baseUrl &&
    result.meta?.response?.status === 401 &&
    result.error?.data === 'Unauthorized Access'
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const { refreshToken } = (api.getState() as RootState).session;

        const refreshResult = await baseQuery(
          {
            url: 'refresh_access_token',
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          },
          api,
          extraOptions,
        );

        const errorData = refreshResult.error?.data as string;

        if (errorData) {
          if (errorData === 'Access denied') {
            // eslint-disable-next-line no-throw-literal
            throw 'no valid refresh token';
          }
          throw errorData;
        }

        if (refreshResult.data) {
          await api.dispatch(
            setAccessToken(
              (refreshResult.data as { access_token: string }).access_token,
            ),
          );
          const { accessToken } = (api.getState() as RootState).session;
          // Retry the initial query
          result = await baseQuery(
            accessToken
              ? {
                  ...(args as FetchArgs),
                  headers: { Authorization: `Bearer ${accessToken}` },
                }
              : args,
            api,
            extraOptions,
          );
        } else {
          api.dispatch(logoutThunk());
          window.location.href = '/login';
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      const { accessToken } = (api.getState() as RootState).session;

      result = await baseQuery(
        {
          ...(args as FetchArgs),
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        api,
        extraOptions,
      );
    }
  }

  queryLoger(result);

  if (result.data && (result.data as ResponseBase).status === false) {
    api.dispatch(
      showNotificationSnackBar({
        type: 'error',
        message: i18next.t((result.data as ResponseBase).description),
      }),
    );
    throw new Error((result.data as ResponseBase).description);
  }

  return result;
};

export default customFetchBase;
