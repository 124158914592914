import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationSnackBar, SystemMessageSliceState } from './types';

const initialState: SystemMessageSliceState = {
  notificationSnackBar: {
    isShow: false,
    type: 'error',
    message: 'error',
  },
};

export const systemMessagesSlice = createSlice({
  name: 'systemMessages',
  initialState,
  reducers: {
    hideNotificationSnackBar: (state) => {
      state.notificationSnackBar.isShow = false;
    },
    showNotificationSnackBar: (
      state,
      { payload }: PayloadAction<Omit<NotificationSnackBar, 'isShow'>>,
    ) => {
      state.notificationSnackBar = {
        ...state.notificationSnackBar,
        ...payload,
        isShow: true,
      };
    },
  },
});

export const selectSystemMessagesSlice = (state: RootState) =>
  state.systemMessages;

export const { hideNotificationSnackBar, showNotificationSnackBar } =
  systemMessagesSlice.actions;
