import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TitlePage } from 'entities/page';
import { selectSessionSlice } from 'entities/session';
import { LoginForm } from 'features/authentication/login';
import { Header } from 'features/page';
import { useAppSelector } from 'shared/model';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthorized } = useAppSelector(selectSessionSlice);

  const onComplete = () => {
    navigate('/');
  };

  useEffect(() => {
    if (isAuthorized && location.pathname === '/login') {
      navigate('/');
    }
  }, [location, isAuthorized, navigate]);

  return (
    <>
      <TitlePage title="loginText" />
      <Header hideMenu />
      <LoginForm onComplete={onComplete} />
    </>
  );
};
