import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Modal } from 'features/modal';

type Props = {
  isOpen: boolean;
  handleSubmit: () => void;
  onClose: () => void;
  title: string;
  subtitle: string;
};

export const RemoveDialog: React.FC<Props> = ({
  isOpen,
  handleSubmit,
  onClose,
  title,
  subtitle,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      title="modalText.deleting"
      onDone={handleSubmit}
      onClose={onClose}
      secondaryText="no"
      primaryText="yes"
    >
      <Typography variant="subtitle1">{t(title)}</Typography>
      <Typography variant="body2" mt={1}>
        {t(subtitle)}
      </Typography>
    </Modal>
  );
};
