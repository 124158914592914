import { object, string } from 'yup';

export const coordinatePointsValidationSchema = object().shape({
  lat: string()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'checkField')
    .min(1, 'minCharacter1')
    .max(20, 'maxCharacter20')
    .required('fillField'),
  lng: string()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'checkField')
    .min(1, 'minCharacter1')
    .max(20, 'maxCharacter20')
    .required('fillField'),
});
