export enum DrawType {
  farmland_polygon = 'farmland_polygon',
  unaccounted_areas = 'unaccounted_areas',
  error_area = 'error_area',
  reprocess_area = 'reprocess_area',
  worked_area = 'worked_area',
}

export type MarkerDto = {
  id: number;
  position: [number, number];
  popup: {
    html: string;
  };
};
