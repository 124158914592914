import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography, { TypographyProps } from '@mui/material/Typography';

type Props = {
  value: number;
  size?: number;
  textVariant?: TypographyProps['variant'];
} & CircularProgressProps;

export const CircularProgressWithLabel: React.FC<Props> = ({
  value,
  size,
  textVariant = 'caption',
  ...rest
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        thickness={4}
        size={size}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        value={value * 100}
        sx={{
          position: 'absolute',
          left: 0,
        }}
        size={size}
        {...rest}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant={textVariant}
          component="div"
          color="text.secondary"
        >
          {Math.round(value * 100)}
        </Typography>
      </Box>
    </Box>
  );
};
