import { string } from 'yup';

export const validateYupTextField = string()
  .min(1, 'minCharacter1')
  .max(50, 'maxCharacter50')
  .trim();

export const validateYupPassword = string()
  .min(8, 'passwordShort')
  .matches(/[0-9]/, 'passwordRequiredNumber')
  // .matches(/[a-zA-Z]/, 'passwordLatinLetters')
  .required('fillField');
// .matches(/[a-z]/, 'Password requires a lowercase letter')
// .matches(/[A-Z]/, 'Password requires an uppercase letter')
// .matches(/[^\w]/, 'Password requires a symbol'),

export const validateYupConfirmPassword = string()
  .test('password-match', 'passwordMatch', function (value) {
    return value && this.parent.password
      ? value === this.parent.password
      : true;
  })
  .required('fillField');
