import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { appRouter } from 'pages';
import { PersistGate } from 'redux-persist/integration/react';
import withLocalization from 'shared/localization';

import { theme } from './styles/theme';
import { appStore, persistedStore } from './appStore';
import { SnackbarProvider } from './snackbarProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

dayjs.extend(utc);
dayjs.extend(duration);

const App = () => {
  return (
    <ReduxProvider store={appStore}>
      <PersistGate loading={null} persistor={persistedStore}>
        <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider>
                <CssBaseline />
                <RouterProvider router={appRouter} />
              </SnackbarProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

export default withLocalization(App);
