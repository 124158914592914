import { useTranslation } from 'react-i18next';
import { UnitTypeDto } from 'entities/catalog';
import { Input } from 'shared/ui';

type Props = {
  unitTypesList?: Array<UnitTypeDto>;
};

export const UnitForm: React.FC<Props> = ({ unitTypesList }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Input
        select
        label="type"
        name="unit_type"
        defaultValue={-1}
        defaultMenuItems={{
          value: '-1',
          text: 'wayStatusNone',
        }}
        menuItems={
          unitTypesList &&
          unitTypesList.reduce((obj, item) => {
            obj[item.type_id] =
              item[
                `unit_type_name_${i18n.language}` as keyof Omit<
                  UnitTypeDto,
                  'type_id'
                >
              ];
            return obj;
          }, {} as { [id: number]: string })
        }
      />
      <Input
        label="model"
        name="model"
        placeholder={`${t('placeholderList.insertModel')}`}
        InputLabelProps={{ shrink: true }}
      />
      <Input
        label="number"
        name="number"
        placeholder={`${t('placeholderList.insertNumber')}`}
        InputLabelProps={{ shrink: true }}
      />
      <Input
        type="number"
        label="workingWidth"
        name="width"
        placeholder={`${t('placeholderList.insertWidth')}`}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputProps: {
            step: 0.1,
          },
        }}
      />
    </>
  );
};
