import { LinearProgress, Paper, Typography } from '@mui/material';

type Props = {
  title: string;
  subtitle: string;
  progress?: number;
};

export const CardInfo: React.FC<Props> = ({ title, subtitle, progress }) => {
  return (
    <Paper sx={{ minWidth: 300, height: 130, padding: '16px 12px' }}>
      <Typography sx={{ fontSize: 30, marginBottom: 1 }}>{title}</Typography>
      <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
        {subtitle}
      </Typography>
      {progress && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ marginTop: 1 }}
        />
      )}
    </Paper>
  );
};
