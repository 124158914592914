import { Box, Skeleton } from '@mui/material';

export const SkeletonMorePage: React.FC = () => {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Skeleton variant="rectangular" width={200} height={44} />
      </Box>

      <Skeleton
        variant="rectangular"
        width="340px"
        height="98px"
        sx={{ borderRadius: '4px', marginBottom: 2 }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height="24px"
        sx={{ borderRadius: '4px', marginBottom: 2 }}
      />
      <Box sx={{ display: 'flex', gap: 3, mb: 7.5 }}>
        <Skeleton
          variant="rectangular"
          width="300px"
          height="130px"
          sx={{ borderRadius: '4px' }}
        />
        <Skeleton
          variant="rectangular"
          width="300px"
          height="130px"
          sx={{ borderRadius: '4px' }}
        />
        <Skeleton
          variant="rectangular"
          width="300px"
          height="130px"
          sx={{ borderRadius: '4px' }}
        />
      </Box>

      <Skeleton
        variant="rectangular"
        width="100%"
        height="130px"
        sx={{ borderRadius: '4px' }}
      />
    </>
  );
};
