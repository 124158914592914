import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Typography,
} from '@mui/material';

type Props = {
  title: string;
  btnBack?: {
    onClick: () => void;
  };
  btnData?: Array<
    ButtonProps & {
      title: string;
    }
  >;
};

export const HeaderPage: React.FC<Props> = ({ title, btnData, btnBack }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
      <Box sx={{ position: 'relative' }}>
        {btnBack && (
          <IconButton
            onClick={btnBack.onClick}
            sx={{ position: 'absolute', left: -44, top: -4 }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography variant="h6">{t(title)}</Typography>
      </Box>

      {btnData && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          {btnData.map(({ title, variant = 'contained', ...rest }, index) => (
            <Button key={index} variant={variant} {...rest}>
              {t(title)}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};
