import React from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';

type Props = {
  handleRemove?: () => void;
  handleEdit?: () => void;
  disabledEdit?: boolean;
};

export const TableCellControls: React.FC<Props> = ({
  handleRemove,
  handleEdit,
  disabledEdit = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {handleEdit && (
        <Tooltip title={t('edit')}>
          <span>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit();
              }}
              disabled={disabledEdit}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      )}

      {handleRemove && (
        <Tooltip title={t('remove')}>
          <span>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                handleRemove();
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};
