import * as turf from '@turf/turf';

export function getPolygonWithProp(
  id: string,
  payload: turf.Position[],
  prop: { [key: string]: string },
) {
  const polygon = turf.polygon([payload], prop);

  return { ...polygon, id };
}
